@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.vidoe_call {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.jost {
  font-family: "Jost", serif;
}

.russo {
  font-family: "Russo One", serif;
  font-weight: 400;
  font-style: normal;
}

@layer components {
  .btn {
    @apply bg-orange-500 text-center pl-4 pr-4 rounded-sm font-semibold;
  }
  .inpt {
    @apply bg-slate-900 rounded-md h-10 w-full
        placeholder:pl-3 text-slate-50 focus:outline-none;
  }

  .textinpt {
    @apply bg-slate-900 rounded-md
        placeholder:pl-3 text-slate-50 focus:outline-none;
  }
}

.control-button {
  background-color: white;
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  cursor: pointer;
}

.control-button img {
  width: 24px;
  height: 24px;
}

.control-button:hover {
  background-color: #e2e8f0; /* Light gray background */
  transform: scale(1.1);
}

.control-button.red {
  background-color: #ef4444; /* Red background for end call */
}

.control-button.red:hover {
  background-color: #dc2626;
  transform: scale(1.1);
}

.button-container {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}
